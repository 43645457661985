import React, { Component } from "react"

class FileUpdloaderArea extends Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDragIn = this.handleDragIn.bind(this)
    this.handleDragOut = this.handleDragOut.bind(this)
    this.fileInput = React.createRef()
    this.state = {
      highlight: false,
      fileUploaded: false,
    }
  }

  handleSubmit(event) {
    event.preventDefault()
    event.stopPropagation()
    this.props.onFileChange(this.fileInput.current.files[0])
    this.setState({
      highlight: true,
      fileUploaded: true,
    })
  }

  handleDragIn(event) {
    event.preventDefault()
    event.stopPropagation()
    this.setState({
      highlight: true,
    })
  }

  handleDragOut(event) {
    event.preventDefault()
    event.stopPropagation()
    this.setState({
      highlight: false,
    })
  }

  handleDrop(event) {
    event.preventDefault()
    event.stopPropagation()
    this.setState({
      highlight: true,
      fileUploaded: true,
    })
    //this.props.onFileChange(this.fileInput.current.files[0])
    this.props.onFileChange(event.dataTransfer.files[0])
  }

  render() {
    var className = this.state.highlight ? "highlight card" : "card"
    return (
      <div
        id="fileUploaderArea"
        onDrop={(e) => this.handleDrop(e)}
        onDragLeave={(e) => this.handleDragOut(e)}
        onDragEnter={(e) => this.handleDragIn(e)}
        onDragOver={(e) => this.handleDragIn(e)}
      >
        <form className={className}>
          <input
            id="fileElem"
            name="fileElem"
            type="file"
            accept="image/*"
            ref={this.fileInput}
            onChange={this.handleSubmit}
          />
          <label className="button" htmlFor="fileElem">
            <div>
              {this.state.fileUploaded
                ? this.props.labels.fileUploaded[this.props.selectedLanguage]
                : this.props.labels.dragAndDrop[this.props.selectedLanguage]}
            </div>
          </label>
        </form>
      </div>
    )
  }
}

export default FileUpdloaderArea
